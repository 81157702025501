// @ts-check

import { createWidget } from "@opendash/core";



export default createWidget({
  type: "opendash-widget-bcm-files",
  meta: {},
  displayComponent: () => import("./component"),
  settingsComponent: () => import("./settings"),
  presets: [
    {
      label: "bcm:widgets.files.name",
      description: "bcm:widgets.files.description",
      imageLink: require("./screenshot.png"),
      layout: [6, 8],
      widget: {
        config: {
          blocklist: [],
        },
      },
    },
  ],
});
