//@ts-check

if (!window.localStorage.getItem("opendash:language")) {
  window.localStorage.setItem("opendash:language", '"en"');
}

import "antd/dist/antd.variable.min.css";

import { init, StorageAdapterLS } from "@opendash/core";
import { registerIconPack } from "@opendash/icons";
import { $parse, ParsePlugin } from "@opendash/plugin-parse";
// import { ParseAdminPlugin } from "@opendash/plugin-parse-admin";
import { OpenwarePlugin } from "@opendash/plugin-openware";
import { HighchartsPlugin } from "@opendash/plugin-highcharts";
import { BDEPlugin } from "@opendash/plugin-bde";

import "./parse.config";
import "./leaflet.config";
import "./highcharts.config";

import parseAdminConfig from "./parse-admin.json";

import { Global as BCMGlobalComponent } from "./components/Global";

if (window.location.hostname === "bcm-kompetenzzentrum.openinc.dev") {
  // @ts-ignore
  window.Kompetenzzentrum = true;
  console.log("window.Kompetenzzentrum = true");
}

init("opendash", async (factory) => {
  const isKompetenzzentrum = "Kompetenzzentrum" in window;

  // UI
  // @ts-ignore
  registerIconPack(await import("@opendash/icons/dist/fa-regular.json"));

  // factory.ui.setAuthSignupForm(AuthSignupForm);
  factory.ui.setLogoImage("/bcmLogo.jpg");

  if (isKompetenzzentrum) {
    factory.setPrimaryColor("#bb1d1a");
  } else {
    factory.setPrimaryColor("#1770b8");
  }

  window.document.title = "BCM Suite";

  // Global Component + Footer

  factory.registerGlobalComponent(BCMGlobalComponent);

  // Plugins
  factory.registerDeviceStorageAdapter(new StorageAdapterLS());

  await factory.use(
    new ParsePlugin({
      useLiveQueries: false,
      // async authPayloadMapper(payload) {
      //   return {
      //     ...payload,
      //     // @ts-ignore
      //     rsAuthority: new Authority({ id: payload.rsAuthority }),
      //   };
      // },
    })
  );

  // await factory.use(new ParseAdminPlugin(parseAdminConfig));
  await factory.use(
    new OpenwarePlugin({
      secure: true,
      host: "openware.apps.openinc.dev",
    })
  );

  await factory.use(new HighchartsPlugin());
  await factory.use(new BDEPlugin());

  // Translations

  factory.registerLanguage("en", "English");

  factory.registerTranslationResolver(
    "en",
    "bcm",
    () => import("./translations/en/bcm.json")
  );

  factory.registerTranslationResolver(
    "en",
    "parse-custom",
    () => import("./translations/en/parse-custom.json")
  );

  factory.registerAntDesignTranslation(
    "en",
    () => import("antd/lib/locale/en_US")
  );

  // Navigation:

  factory.registerStaticNavigationGroup({
    id: "admin/back-to-dashboard/group",
    label: "bcm:admin.back_to_dashboard_group",
    order: 1,
  });

  factory.registerStaticNavigationItem({
    id: `admin/back-to-dashboard/item`,
    group: "admin/back-to-dashboard/group",
    place: "sidebar",
    order: 10,

    label: "bcm:admin.back_to_dashboard_item",
    // icon: "string",
    // color: "string",

    link: "/monitoring/dashboards",
    // event: "string",

    routeCondition: "/admin/**/*",
    activeCondition: "/monitoring/dashboards",
  });

  factory.registerStaticNavigationGroup({
    id: "admin/parse",
    label: "bcm:admin.label",
    order: 2,
  });

  parseAdminConfig
    .filter((config) => config.className.startsWith("BCM_"))
    .forEach((config, i) => {
      factory.registerStaticNavigationItem({
        id: `admin/parse/${config.className}`,
        group: "admin/parse",
        place: "sidebar",
        order: i,

        label: `parse-custom:classes.${config.className}.label_plural`,
        // icon: "string",
        // color: "string",

        link: `/admin/parse/${config.className}`,
        // event: "string",

        routeCondition: "**/*",
        activeCondition: `/admin/parse/${config.className}`,
      });
    });

  // factory.registerStaticNavigationItem({
  //   id: "frontpage/monitoring",
  //   group: "frontpage",
  //   place: "frontpage",
  //   order: 10,
  //   label: `bcm:dashboard.label`,
  //   icon: "fa:chart-line",
  //   color: "#0f70b7",

  //   link: `/monitoring/dashboards`,

  //   routeCondition: "**/*",
  //   activeCondition: `/monitoring/dashboards`,
  // });

  // factory.registerStaticNavigationItem({
  //   id: "frontpage/admin",
  //   group: "frontpage",
  //   place: "frontpage",
  //   order: 10,
  //   label: `bcm:admin.label`,
  //   icon: "fa:tools",
  //   color: "#ce2924",

  //   link: `/admin/parse/BCM_Employee`,

  //   routeCondition: "**/*",
  //   activeCondition: `/admin/parse/Employee`,
  // });

  for (const config of parseAdminConfig) {
    $parse.ui.setClassConfig({
      className: config.className,
      titleFields: config.relation,
      displayFields: config.columns,
      createFields: config.create_fields,
      editFields: config.edit_fields,
      disableACLEditing: true,
      orderField:
        config.className === "BCM_Task" || config.className === "BCM_TaskState"
          ? "order"
          : undefined,
    });

    $parse.ui.setDefaultView(config.className, {
      type: "table",
      fields: config.columns,
    });
  }

  $parse.ui.setDefaultView("BCM_Task", {
    type: "canban",
    groupByField: "state",
    titleFields: ["label"],
    descriptionFields: ["startDate", "endDate"],
  });

  // Widgets

  factory.registerWidget(require("./widgets/gantt"));
  factory.registerWidget(require("./widgets/scheduler"));
  factory.registerWidget(require("./widgets/project-tasks"));
  factory.registerWidget(require("./widgets/contacts"));
  factory.registerWidget(require("./widgets/files"));
  factory.registerWidget(require("./widgets/shipping"));
  factory.registerWidget(require("./widgets/bia"));
  // factory.registerWidget(require("./widgets/social-feed"));
  // factory.registerWidget(require("./widgets/social-analysis"));
  factory.registerWidget(require("./widgets/weather-map"));
  factory.registerWidget(require("./widgets/weather-forecast"));
  factory.registerWidget(require("./widgets/cap-map"));
  factory.registerWidget(require("./widgets/traffic-map"));
  factory.registerWidget(require("./widgets/webcam"));
}).then(async () => {
  console.log("init open.DASH");
});
