import { createWidget } from "@opendash/core";



export default createWidget({
  type: "opendash-widget-bcm-bia",
  meta: {},
  displayComponent: () => import("./component"),
  settingsComponent: () => import("./settings"),
  presets: [
    {
      label: "bcm:widgets.bia.name_tree",
      description: "bcm:widgets.bia.description_tree",
      imageLink: require("./screenshot.png"),
      layout: [12, 8],
      widget: {
        config: {
          display: "tree",
        },
      },
    },
    {
      label: "bcm:widgets.bia.name_graph",
      description: "bcm:widgets.bia.description_graph",
      imageLink: require("./screenshot.png"),
      layout: [8, 8],
      widget: {
        config: {
          display: "graph",
        },
      },
    },
  ],
});
