const _jsxFileName = "C:\\Users\\sz\\Code\\openinc-projects\\bcm-dashboard\\src\\js\\components\\Global.tsx";import { AppPortal } from "@opendash/core";

import * as React from "react";

import styled from "styled-components";

const Footer = styled.div`
  background: white;
  padding: 20px;

  border-top: 1px solid rgba(0, 0, 0, 0.2);
`;

const FooterRow = styled.div`
  gap: 20px;
  display: flex;
`;

const FooterHeader = styled.div`
  font-weight: bold;

  margin-bottom: 20px;
`;
const FooterInfo = styled.div`
  width: 50%;
`;
const FooterLinks = styled.div`
  width: 25%;

  a {
    display: block;
  }
`;
const FooterLogos = styled.div`
  width: 25%;

  img {
    width: 50%;
    max-width: 233px;
  }
`;
const FooterCopyright = styled.div`
  width: 100%;

  padding-top: 20px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);

  text-align: center;
`;

export const Global = React.memo(function BCMGlobal({}) {
  console.log("Global");

  const [state, setState] = React.useState(0);

  const isKompetenzzentrum = "Kompetenzzentrum" in window;

  React.useEffect(() => {
    setTimeout(() => {
      setState((x) => x + 1);
    }, 1000);
  }, []);
  return (
    React.createElement(React.Fragment, null
      , React.createElement(AppPortal, { place: "footer", __self: this, __source: {fileName: _jsxFileName, lineNumber: 65}}
        , isKompetenzzentrum && (
          React.createElement(Footer, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 67}}
            , React.createElement(FooterRow, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 68}}
              , React.createElement(FooterInfo, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 69}}
                , React.createElement(FooterHeader, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 70}}, "Problemstellung")
                , React.createElement('p', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 71}}, "Für einen nachhaltigen Umgang mit Energie und Ressourcen und die Umsetzung von Nachhaltigkeitsmaßnahmen ist es zunehmend wichtiger, Daten im Unternehmen zu analysieren und visuell darzustellen. Die Transparenz der Daten soll Unternehmen bei ihrer Entscheidungsfindung und zukünftigen Planung in Richtung Nachhaltigkeit unterstützen. Unternehmen müssen die Daten zur Entscheidungsfindung und Planung visualisieren und analysieren, um nachhaltige Maßnahmen im Unternehmen zu konstruieren."









                )
              )

              , React.createElement(FooterInfo, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 84}}
                , React.createElement(FooterHeader, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 85}}, "Lösungsansatz")
                , React.createElement('p', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 86}}, "Business Continuity Management Suite - das Konzept der Kontinuität ist dem Konzept der Nachhaltigkeit und Resilienz sehr nahe. Es ermöglicht einem Unternehmen einen detaillierten Einblick in die Veränderungen (intern oder extern) und das Bewusstsein anhand von Daten, die sich direkt oder indirekt auf die vom Unternehmen ergriffenen Maßnahmen für Nachhaltigkeit und organisatorische Resilienz auswirken können."








                )
              )
              , React.createElement(FooterLinks, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 97}}
                , React.createElement(FooterHeader, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 98}}, "Links")
                , React.createElement('a', { href: "mailto:hussain.syed@uni-siegen.de", __self: this, __source: {fileName: _jsxFileName, lineNumber: 99}}, "Kontakt : hussain.syed@uni-siegen.de"

                )
                , React.createElement('a', { href: "https://kontikat.de/impressum/", __self: this, __source: {fileName: _jsxFileName, lineNumber: 102}}, "Datenschutz Bestimmungen"

                )
                , React.createElement('a', { href: "https://kontikat.de/impressum/", __self: this, __source: {fileName: _jsxFileName, lineNumber: 105}}, "Impressum")
              )
            )
            , React.createElement(FooterRow, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 108}}
              , React.createElement(FooterCopyright, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 109}}
                , React.createElement('img', {
                  src: "/koze.png",
                  alt: "Mittelstand 4.0 Kompetenzzentrum Siegen"   ,
                  width: 300, __self: this, __source: {fileName: _jsxFileName, lineNumber: 110}}
                )
              )
            )
          )
        )

        , !isKompetenzzentrum && (
          React.createElement(Footer, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 121}}
            , React.createElement(FooterRow, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 122}}
              , React.createElement(FooterInfo, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 123}}
                , React.createElement(FooterHeader, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 124}}, "Über das Projekt"  )
                , React.createElement('p', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 125}}, "(BCM-Suite) Die Business Continuity Management Suite bietet eine Multiuser-Plattform in der Form von Dashboards mit Widget-Store-Funktionalität für den Zugriff auf verschiedene geschäftsrelevante interne und externe Datenquellen. Die Dashboards sind einfach mit Widgets zu konfigurieren, die eine einheitliche Sicht, die es ermöglicht, mehrere Stakeholder im Unternehmen für die Zusammenarbeit zu verbinden und Überwachen Sie sich ändernde Geschäftsprozesse und Faktoren, um sich darauf vorzubereiten potenzielle Krisen."









                )
                , React.createElement('b', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 136}}, "Ansprechpartner:")
                , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 137}} ), "Hussain Abid Syed"

                , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 139}} )
                , React.createElement('a', { href: "mailto:hussain.syed@uni-siegen.de", __self: this, __source: {fileName: _jsxFileName, lineNumber: 140}}, "hussain.syed@uni-siegen.de"

                )
                , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 143}} )
                , React.createElement('a', { href: "https://kontikat.de", __self: this, __source: {fileName: _jsxFileName, lineNumber: 144}}, "kontikat.de")
                , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 145}} )
                , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 146}} )
                , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 147}} )
              )
              , React.createElement(FooterInfo, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 149}}
                , React.createElement(FooterHeader, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 150}}, "Umsetzungspartner: open.INC GmbH"  )
                , React.createElement('p', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 151}}, "Mit unseren Lösungen bieten wir Unternehmen einen flexiblen digitalen Werkzeugkasten, der auf die individuellen Bedürfnisse angepasst werden kann. Datenzugriff, Datenanalyse sowie Datenansicht werden dadurch einfach und intuitiv in den einzelnen Unternehmensbereichen ermöglicht. Dafür bieten wir modulare Softwarelösungen aus einer Hand."






                )
                , React.createElement('b', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 159}}, "Ansprechpartner:")
                , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 160}} ), "Sebastian Zilles"

                , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 162}} )
                , React.createElement('a', { href: "mailto:zilles@openinc.de", __self: this, __source: {fileName: _jsxFileName, lineNumber: 163}}, "zilles@openinc.de")
                , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 164}} )
                , React.createElement('a', { href: "https://openinc.de", __self: this, __source: {fileName: _jsxFileName, lineNumber: 165}}, "openinc.de")
                , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 166}} )
                , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 167}} )
                , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 168}} )
              )
              , React.createElement(FooterLinks, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 170}}
                , React.createElement(FooterHeader, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 171}}, "Links")
                , React.createElement('a', { href: "mailto:hussain.syed@uni-siegen.de", __self: this, __source: {fileName: _jsxFileName, lineNumber: 172}}, "Kontakt : hussain.syed@uni-siegen.de"

                )
                , React.createElement('a', { href: "https://kontikat.de/impressum/", __self: this, __source: {fileName: _jsxFileName, lineNumber: 175}}, "Datenschutz Bestimmungen"

                )
                , React.createElement('a', { href: "https://kontikat.de/impressum/", __self: this, __source: {fileName: _jsxFileName, lineNumber: 178}}, "Impressum")
              )

              , React.createElement(FooterLogos, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 181}}
                , React.createElement(FooterHeader, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 182}}, "Projektträger")

                , React.createElement('img', { src: "/bnd.jpg", alt: "BND", __self: this, __source: {fileName: _jsxFileName, lineNumber: 184}} )
                , React.createElement('img', { src: "/sifo_logo.jpg", alt: "SIFO", __self: this, __source: {fileName: _jsxFileName, lineNumber: 185}} )
              )
            )
            , React.createElement(FooterRow, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 188}}
              , React.createElement(FooterCopyright, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 189}}
                , React.createElement('p', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 190}}, "Urheberrechte © Alle Rechte vorbehalten von Hussain Abid Syed, BMBF-Nachwuchsgruppe KontiKat."


                )

                , React.createElement('img', { src: "/kontikat.jpg", alt: "kontikat", __self: this, __source: {fileName: _jsxFileName, lineNumber: 195}} )
              )
            )
          )
        )
      )
    )
  );
});
