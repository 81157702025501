import "leaflet/dist/leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet-openweathermap";
// import "leaflet.markercluster/dist/leaflet.markercluster";
// import "leaflet.markercluster/dist/MarkerCluster.css";
// import "leaflet.markercluster/dist/MarkerCluster.Default.css";

// import "leaflet.heat/dist/leaflet-heat";

// // L.Icon.Default.imagePath = '.';

const L = window.L;

// @ts-ignore
delete L.Icon.Default.prototype._getIconUrl;

// @ts-ignore
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

// https://www.dwd.de/DWD/warnungen/warnmodul/warnmod_leaflet-betterwms.js

L.TileLayer.BetterWMS = L.TileLayer.WMS.extend({
  onAdd: function (map) {
    // Triggered when the layer is added to a map.
    //   Register a click listener, then do all the upstream WMS things
    L.TileLayer.WMS.prototype.onAdd.call(this, map);
    map.on("click", this.getFeatureInfoJsonp, this);
  },

  onRemove: function (map) {
    // Triggered when the layer is removed from a map.
    //   Unregister a click listener, then do all the upstream WMS things
    L.TileLayer.WMS.prototype.onRemove.call(this, map);
    map.off("click", this.getFeatureInfoJsonp, this);
  },

  getFeatureInfo: function (evt) {
    // Make an AJAX request to the server and hope for the best
    const url = this.getFeatureInfoUrl(evt.latlng);
    const showResults



 = L.Util.bind(this.showGetFeatureInfo, this);

    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        const error = typeof data === "string" ? null : data;

        showResults(error, evt.latlng, data);
      })
      .catch((error) => {
        showResults(error);
      });
  },

  // Warnmodul2: JSONP-Version der getFeatureInfo-Funktion
  getFeatureInfoJsonp: function (evt) {
    // Make an AJAX request to the server and hope for the best
    const url = this.getFeatureInfoUrl(evt.latlng);

    const showResultsJson



 = L.Util.bind(this.showGetFeatureInfoJson, this);

    fetchJsonp(url, "parseResponse")
      .then((data) => {
        showResultsJson(evt.latlng, data);
      })
      .catch((error) => {
        console.error(error);
      });
  },

  getFeatureInfoUrl: function (latlng) {
    // Construct a GetFeatureInfo request URL given a point
    var point = this._map.latLngToContainerPoint(latlng, this._map.getZoom()),
      size = this._map.getSize(),
      params = {
        request: "GetFeatureInfo",
        service: "WMS",
        srs: "EPSG:4326",
        styles: this.wmsParams.styles,
        transparent: this.wmsParams.transparent,
        version: this.wmsParams.version,
        format: this.wmsParams.format,
        bbox: this._map.getBounds().toBBoxString(),
        height: size.y,
        width: size.x,
        layers: this.wmsParams.layers,
        query_layers: this.wmsParams.layers,
        info_format: "text/javascript",
        // Warnmodul2: nur ausgewÃ¤hlte Properties werden abgefragt - eine ungefilterte Antwort liefert eine Vielzahl weiterer Eigenschaften der Warnungen, analog zum Inhalt im CAP-Format
        propertyName: "EVENT,ONSET,EXPIRES,SENT",
        // Warnmodul2: FEATURE_COUNT > 1 notwendig, um im Falle Ã¼berlappender Warnungen alle Warnungen abzufragen
        FEATURE_COUNT: 50,
      };

    params[params.version === "1.3.0" ? "i" : "x"] = point.x;
    params[params.version === "1.3.0" ? "j" : "y"] = point.y;

    return this._url + L.Util.getParamString(params, this._url, true);
  },

  showGetFeatureInfo: function (err, latlng, content) {
    if (err) {
      console.log(err);
      return;
    } // do nothing if there's an error
    console.log("showGetFeatureInfo: " + content);
    // Otherwise show the content in a popup, or something.
    L.popup({ maxWidth: 800 })
      .setLatLng(latlng)
      .setContent(content)
      .openOn(this._map);
  },

  // Warnmodul2: angepasste Funktion zum Aufbereiten der Geoserver-Antwort (Auswahl von bestimmten properties) und zur Anzeige als Popup
  showGetFeatureInfoJson: function (latlng, data) {
    if (data.features[0] == null) {
      return 0;
    }
    var content = "<h2>Amtliche Warnung</h2>";
    data.features.forEach(function (item, i) {
      var o = new Date(item.properties.ONSET);
      var e = new Date(item.properties.EXPIRES);
      const onset =
        ("0" + o.getDate()).slice(-2) +
        "." +
        ("0" + (o.getMonth() + 1)).slice(-2) +
        ". - " +
        ("0" + o.getHours()).slice(-2) +
        ":" +
        ("0" + o.getMinutes()).slice(-2) +
        " Uhr";
      const end =
        ("0" + e.getDate()).slice(-2) +
        "." +
        ("0" + (e.getMonth() + 1)).slice(-2) +
        ". - " +
        ("0" + e.getHours()).slice(-2) +
        ":" +
        ("0" + e.getMinutes()).slice(-2) +
        " Uhr";
      content +=
        "<p><table><tr><td>Ereignis:</td><td><b>" +
        item.properties.EVENT +
        "</b></td></tr>";
      content += "<tr><td>Beginn:</td><td>" + onset + "</td></tr>";
      content += "<tr><td>Ende:</td><td>" + end + "</td></tr></table></p>";
      //content += "Gesendet: " + item.properties.SENT + "</p>";
    });
    content +=
      "<a target='blank' href='https://www.dwd.de/warnungen'>www.dwd.de/warnungen</a>";

    L.popup({ maxWidth: 800 })
      .setLatLng(latlng)
      .setContent(content)
      .openOn(this._map);
  },
});

L.tileLayer.betterWms = function (url, options) {
  return new L.TileLayer.BetterWMS(url, options);
};

function fetchJsonpClearFunction(functionName) {
  // IE8 throws an exception when you try to delete a property on window
  // http://stackoverflow.com/a/1824228/751089
  try {
    // @ts-ignore
    delete window[functionName];
  } catch (e) {
    // @ts-ignore
    window[functionName] = undefined;
  }
}

function fetchJsonpRemoveScript(scriptId) {
  const script = document.getElementById(scriptId);
  if (script) {
    document.getElementsByTagName("head")[0].removeChild(script);
  }
}

function fetchJsonp(
  _url,
  callbackFunction
) {
  // to avoid param reassign
  let url = _url;
  const timeout = 10000;
  const jsonpCallback = "callback";

  let timeoutId;

  return new Promise((resolve, reject) => {
    const scriptId = `${jsonpCallback}_${callbackFunction}`;

    // @ts-ignore
    window[callbackFunction] = (response) => {
      resolve(response);

      if (timeoutId) clearTimeout(timeoutId);

      fetchJsonpRemoveScript(scriptId);

      fetchJsonpClearFunction(callbackFunction);
    };

    // Check if the user set their own params, and if not add a ? to start a list of params
    url += url.includes("?") ? "&" : "?";

    const jsonpScript = document.createElement("script");

    jsonpScript.id = scriptId;
    jsonpScript.setAttribute(
      "src",
      `${url}${jsonpCallback}=${callbackFunction}`
    );

    document.getElementsByTagName("head")[0].appendChild(jsonpScript);

    timeoutId = setTimeout(() => {
      reject(new Error(`JSONP request to ${_url} timed out`));

      fetchJsonpClearFunction(callbackFunction);
      fetchJsonpRemoveScript(scriptId);
      // @ts-ignore
      window[callbackFunction] = () => {
        fetchJsonpClearFunction(callbackFunction);
      };
    }, timeout);

    // Caught if got 404/500
    jsonpScript.onerror = () => {
      reject(new Error(`JSONP request to ${_url} failed`));

      fetchJsonpClearFunction(callbackFunction);
      fetchJsonpRemoveScript(scriptId);
      if (timeoutId) clearTimeout(timeoutId);
    };
  });
}
