// @ts-check

import { createWidget } from "@opendash/core";



export default createWidget({
  type: "opendash-widget-bcm-webcam",
  meta: {},
  displayComponent: () => import("./component"),
  settingsComponent: () => import("./settings"),
  presets: [
    {
      label: "bcm:widgets.webcam.name",
      description: "bcm:widgets.webcam.description",
      imageLink: require("./screenshot.png"),
      layout: [6, 8],
      widget: {
        config: {
          url: "",
        },
      },
    },
  ],
});
